import * as React from 'react'
import Svg, { FillProps, Path } from 'react-native-svg'

export default ({ fill }: FillProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <Path
      d="M14.0066 11.747L12.0833 10.637V6.58269C12.0833 6.33958 11.9868 6.10642 11.8149 5.93451C11.6429 5.7626 11.4098 5.66603 11.1667 5.66603C10.9236 5.66603 10.6904 5.7626 10.5185 5.93451C10.3466 6.10642 10.25 6.33958 10.25 6.58269V11.166C10.25 11.327 10.2923 11.4851 10.3728 11.6245C10.4532 11.7639 10.569 11.8796 10.7083 11.9601L13.09 13.3351C13.1942 13.3953 13.3094 13.4344 13.4288 13.4502C13.5482 13.466 13.6695 13.458 13.7858 13.4269C13.9022 13.3957 14.0112 13.342 14.1068 13.2686C14.2024 13.1953 14.2825 13.1039 14.3427 12.9996C14.4029 12.8953 14.442 12.7801 14.4577 12.6607C14.4734 12.5413 14.4654 12.42 14.4342 12.3037C14.403 12.1873 14.3492 12.0783 14.2758 11.9828C14.2024 11.8873 14.111 11.8072 14.0066 11.747ZM11.1667 1.99936C9.35368 1.99936 7.58139 2.53697 6.07394 3.54422C4.56649 4.55147 3.39158 5.9831 2.69778 7.65809C2.00397 9.33308 1.82244 11.1762 2.17614 12.9544C2.52984 14.7325 3.40288 16.3659 4.68486 17.6478C5.96684 18.9298 7.60018 19.8029 9.37834 20.1566C11.1565 20.5103 12.9996 20.3287 14.6746 19.6349C16.3496 18.9411 17.7812 17.7662 18.7885 16.2588C19.7957 14.7513 20.3333 12.979 20.3333 11.166C20.3306 8.7357 19.364 6.4057 17.6455 4.6872C15.927 2.9687 13.597 2.00206 11.1667 1.99936ZM11.1667 18.4994C9.71627 18.4994 8.29845 18.0693 7.09249 17.2635C5.88653 16.4577 4.9466 15.3124 4.39155 13.9724C3.83651 12.6324 3.69129 11.1579 3.97425 9.73536C4.2572 8.31284 4.95564 7.00616 5.98122 5.98058C7.00681 4.95499 8.31348 4.25656 9.73601 3.9736C11.1585 3.69064 12.633 3.83587 13.973 4.39091C15.313 4.94595 16.4583 5.88588 17.2641 7.09184C18.0699 8.2978 18.5 9.71563 18.5 11.166C18.4978 13.1103 17.7244 14.9742 16.3497 16.349C14.9749 17.7238 13.1109 18.4971 11.1667 18.4994Z"
      fill={fill}
    />
  </Svg>
)
