import * as React from 'react'
import Svg, { FillProps, Path } from 'react-native-svg'

export default ({ fill }: FillProps) => (
  <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <Path
      d="M10.9999 1.83334C9.18693 1.83334 7.41465 2.37096 5.9072 3.37821C4.39974 4.38545 3.22483 5.81709 2.53103 7.49208C1.83722 9.16707 1.65569 11.0102 2.00939 12.7883C2.36309 14.5665 3.23613 16.1998 4.51811 17.4818C5.80009 18.7638 7.43344 19.6368 9.2116 19.9905C10.9898 20.3442 12.8329 20.1627 14.5079 19.4689C16.1828 18.7751 17.6145 17.6002 18.6217 16.0927C19.629 14.5853 20.1666 12.813 20.1666 11C20.1666 9.79623 19.9295 8.60423 19.4688 7.49208C19.0082 6.37993 18.3329 5.3694 17.4817 4.5182C16.6305 3.66699 15.62 2.99178 14.5079 2.53111C13.3957 2.07045 12.2037 1.83334 10.9999 1.83334ZM10.9999 18.3333C9.055 18.3333 7.18974 17.5607 5.81447 16.1855C4.43921 14.8102 3.66659 12.9449 3.66659 11C3.66456 9.37141 4.21019 7.78943 5.21576 6.50834L15.4916 16.7842C14.2105 17.7897 12.6285 18.3354 10.9999 18.3333ZM16.7841 15.4917L6.50826 5.21584C7.78934 4.21027 9.37132 3.66465 10.9999 3.66668C12.9448 3.66668 14.8101 4.43929 16.1854 5.81456C17.5606 7.18983 18.3333 9.05509 18.3333 11C18.3353 12.6286 17.7897 14.2106 16.7841 15.4917Z"
      fill={fill}
    />
  </Svg>
)
